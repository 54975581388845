import React from 'react'
import "./projekt.css"
import GitHubIcon from '@mui/icons-material/GitHub';

function SearchPage() {
    return (
        <div className="searchPage">
            <h1>CLI game</h1>
            <br/>
            <div className='gitlink'>

            <a href="https://github.com/LeviusN/CLIgame"><button><GitHubIcon /></button></a>
            </div>
            <br/>
            <p>It is a game created in command line using javascript. </p>
            <p>Who invented AC technology? </p>
            <li>Albert Einstein</li>
            <li>Nikola Tesla</li>
            <li>Max Planck</li>
            <li>Donald Trump</li>
     
        </div>
    )
}

export default SearchPage
