
import "./aboutme.css"
import React from 'react';
import niki from '../images/niki.png'
import vut from '../images/vut.png'
import Age from './agetime'


function SearchPage() {
    
    return (
        <div className="aboutmain">
            <div className="name">
            <h1>Niki Popara</h1>
            </div>

            <div className="age">
            <Age />
            
            </div>
            <div class="break"></div>
            <div className="about">
            <p>I am a person who is positive about every aspect of life. There are many things I like to do, to see, and to experience. I like to read, I like to write; I like to think, I like to dream; I like to talk, I like to listen. I like to see the sunrise in the morning, I like to see the moonlight at night; I like to feel the music flowing on my face, I like to smell the wind coming from the ocean. I like to look at the clouds in the sky with a blank mind, I like to do thought experiment when I cannot sleep in the middle of the night. I like flowers in spring, rain in summer, leaves in autumn, and snow in winter. I like to sleep early, I like to get up late; I like to be alone, I like to be surrounded by people. I like country’s peace, I like metropolis’ noise; I like the beautiful west lake in Hangzhou, I like the flat cornfield in Champaign. I like delicious food and comfortable shoes; I like good books and romantic movies. I like the land and the nature, I like people. And, I like to laugh</p>
            </div>

            <div className="foto1">
            <img className="fotoniki" src={niki} alt="" />
            </div>
            <div class="break"></div>
            <div className="foto2">
            <img className="fotoschool" src={vut} alt="" />
            </div>

            <div className="school">
            <p>VUT/FSI Mechatronics</p>
            <li>2016 - 2021</li>

            <p>STU/FEI robotics</p>
            <li>2016 - 2021</li>
            </div>
            <div class="break"></div>
            <div className="webdev">
                <h3>Web dev</h3>
                <li>Javascript</li>
                <li>React</li>
                <li>HTML/CSS</li>
                <li>Node js</li>
                <li>PHP</li>
                <li>MySQL</li>
            </div>
            <div className="robotics">
                <h3>Robotics</h3>
                <li>Matlab</li>
                <li>Simulink</li>
                <li>Python</li>
                <li>C</li>
                <li>Ansys</li>
            </div>
            <div className="design">
                <h3>Design</h3>
                <li>Solidworks</li>
                <li>CAD</li>
                <li>Inventor</li>
            </div>
            
            <div className="other">
            <h3>Other</h3>
            <li>3D printing</li>
            <li>Soldering</li>
            <li>DIY</li>
            </div>
            <br/>


        </div>
    )
}

export default SearchPage
