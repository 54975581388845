import Avatar from '@mui/material/Avatar';
import React from 'react';
import "./VideoCard.css"
import {Link} from "react-router-dom";


function VideoCard({image, title, channel, views, timestamp, channelImage}) {
    return (
        <div className="videoCard">
           <Link to={`/search/${title}`}>
           <img className="videoCard__thumbnail" src={image} alt=""/>
           </Link>
           <div className="videoCard__info">
               <Avatar 
                className="videoCard_avatar" 
                alt={channel} 
                src={channelImage} 
               />
           
           <div className="videoCard__text">
               <h4>{title}</h4>
               <p>{channel}</p>
               <p>{views} {timestamp}</p>

           </div>
           </div>
        </div>
    );
}

export default VideoCard
