import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import AboutMe from './pages/aboutme';
import Allprojects from './pages/AllProjects'
import Snake from './pages/snake';
import Like from './pages/Like'
import Project1 from './projects/project1';
import Project2 from './projects/project2';
import Project3 from './projects/project3';
import Project4 from './projects/project4';
import Project5 from './projects/project5';
import Project6 from './projects/project6';
import Project7 from './projects/project7';
import Project8 from './projects/project8';
import RecommendedVideos from "./RecommendedVideos"
import './App.css';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
//import SearchPage from "./SearchPage";

function App() {
  return (
    <div className="app">
      <Router>
        <Header />
        <Switch>
          <Route path="/search/Robotic manipulator in 3D space">
          <div className="app__page">
                <Sidebar />
                <Project1 />
            </div>
          </Route>
          <Route path="/search/Genetic Algorithm">
          <div className="app__page">
                <Sidebar />
                <Project2 />
            </div>
          </Route>
          <Route path="/search/I2C comunication with STM">
          <div className="app__page">
                <Sidebar />
                <Project3 />
            </div>
          </Route>
          <Route path="/search/Usart control">
          <div className="app__page">
                <Sidebar />
                <Project4 />
            </div>
          </Route>

          <Route path="/search/File Download Upload">
          <div className="app__page">
                <Sidebar />
                <Project5 />
            </div>
          </Route>

          <Route path="/search/CLI game">
          <div className="app__page">
                <Sidebar />
                <Project6 />
            </div>
          </Route>

          <Route path="/search/Algorithms">
          <div className="app__page">
                <Sidebar />
                <Project7 />
            </div>
          </Route>

          <Route path="/search/Refil">
          <div className="app__page">
                <Sidebar />
                <Project8 />
            </div>
          </Route>

          <Route path="/about">
          <div className="app__page">
                <Sidebar />
                <AboutMe />
            </div>
          </Route>

          <Route path="/allprojects">
          <div className="app__page">
                <Sidebar />
                <Allprojects />
            </div>
          </Route>

          <Route path="/game">
          <div className="app__page">
                <Sidebar />
                <Snake />
            </div>
          </Route>

          <Route path="/like">
          <div className="app__page">
                <Sidebar />
                <Like />
            </div>
          </Route>

          <Route path="/">
              <div className="app__page">
                <Sidebar />
                <RecommendedVideos />
            </div>
          </Route>


        </Switch>
      </Router>
     </div>
  );
}

export default App;
