import "./aboutme.css"
import React from 'react';


function SearchPage() {
    
    return (
        <div className="aboutmain">

            <div class="break"></div>
            <div className="webdev">
                <h3>Smart Home</h3>

            </div>
            <div className="robotics">
                <h3>Meteo station</h3>
            </div>
            <div className="design">
                <h3>Moving Table</h3>
            </div>
            
            <div className="other">
            <h3>Adaptivne_riadenie_1</h3>
            </div>
            <div className="other">
            <h3>Genetic_Algorithm</h3>
            </div>
            <div className="other">
            <h3>Robotic_manipulator_in_3D_space</h3>
            </div>
            <div className="other">
            <h3>File_Download_Upload</h3>
            </div>
            <div className="other">
            <h3>Identifikacia_1rad_2rad</h3>
            </div>
            <div className="other">
            <h3>CLIgame</h3>
            </div>
            <div className="other">
            <h3>RMNS</h3>
            </div>
            <div className="other">
            <h3>REFIL</h3>
            </div>
            <div className="other">
            <h3>I2C_senzor</h3>
            </div>
            <div className="other">
            <h3>Display</h3>
            </div>
            <div className="other">
            <h3>Prerusenie_LED_tlacidlo</h3>
            </div>
            <div className="other">
            <h3>Register-GPIO-LED</h3>
            </div>
            <div className="other">
            <h3>USART_riadenie</h3>
            </div>
            <div className="other">
            <h3>DMA_USART</h3>
            </div>
            <div className="other">
            <h3>USART_komunikacia</h3>
            </div>
            <div className="other">
            <h3>Pouzitie_MX_cube</h3>
            </div>
            <div className="other">
            <h3>Prerusenie_upravene</h3>
            </div>
            <div className="other">
            <h3>LED_a_TLACIDLO</h3>
            </div>

            <br/>
        </div>
    )
}

export default SearchPage