import React from 'react'
import "./projekt.css"
import GitHubIcon from '@mui/icons-material/GitHub';

import Img1 from '../images/graf4.png'
import Img2 from '../images/graf5.png'
import Img3 from '../images/obstecles.png'
import Img4 from '../images/path.png'
function SearchPage() {

    return (
        <div className="searchPage">
            <h1>Genetic Algorithm</h1>
            <br/>
            <div className='gitlink'>

            <a href="https://github.com/LeviusN/Genetic_Algorithm"><button><GitHubIcon /></button></a>
            </div>
            <br/>
            <p>In this projet is created library for GA. Program is created in Python. </p>
            <p>Library is containing following functios </p>
            <li>selbest</li>
            <li>selrand</li>
            <li>crossov</li>
            <li>muta</li>
            <li>mutx</li>
            <li>genpop</li>
            <br/>
            <h3>selbest</h3>
            <p>The function copies from the old population into the new population required a number of strings according to their fitness. The number of the selected strings depends on the vector Nums as follows: Nums=[number of copies of the best string, ... ,number of copies of the i-th best string, ...] The best string is the string with the lowest value of its objective</p>
            <br/>
            <h3>selrand</h3>
            <p>The function selects randomly from the old population a required number of strings.</p>
            <br/>
            <h3>crossov</h3>
            <p>The function creates a new population of strings, which rises after 1- to 4-point crossover operation of all (couples of) strings of the old population. The selection of strings into couples is either random or the neighbouring strings are selected, depending on the parameter sel.</p>
            <br/>
            <h3>muta</h3>
            <p>The function mutates the population of strings with the intensity proportional to the parameter rate from interval 0;1. Only a few genes from a few strings are mutated in the population. The mutations are realized by addition or substraction of random real-numbers to the mutated genes. The absolute values of the added constants are limited by the vector Amp. Next the mutated strings are limited using boundaries defined in a two-row matrix Space. The first row of the matrix represents the lower boundaries and the second row represents the upper boundaries of corresponding genes.</p>
            <br/>
            <h3>mutx</h3>
            <p>The function mutates the population of strings with the intensity proportional to the parameter rate from interval 0;1. Only a few genes from a few strings are mutated in the population. The mutated values are selected from the bounded real-number space, which is defined by the two-row matrix Space. The first row of the matrix represents the lower boundaries and the second row represents the upper boundaries of corresponding genes in the strings.</p>
            <br/>
            <h3>genpop</h3>
            <p>The function generates a population of random real-coded strings which items (genes) are limited by a two-row matrix Space. The first row of the matrix Space consists of the lower limits and the second row consists of the upper limits of the possible values of genes. %The length of the string is equal to the length of rows of the matrix Space.</p>

            <img className='foto' src={Img1} alt="" />
            <img className='foto' src={Img2} alt="" />
            <img className='foto' src={Img3} alt="" />
            <img className='foto' src={Img4} alt="" />
        </div>
    )
}

export default SearchPage
