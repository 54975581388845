import "./aboutme.css"
import React from 'react';
import {AiFillLike} from "react-icons/ai"

function SearchPage() {
    
    return (
        <div className="aboutmain">
            <AiFillLike size={70}/>
        </div>
    )
}

export default SearchPage