import React from 'react'
import "./projekt.css"
import GitHubIcon from '@mui/icons-material/GitHub';
import Img1 from '../images/graftravel.png'
import Img2 from '../images/diagram.png'

function SearchPage() {
    return (
        <div className="searchPage">
            <h1>Sorting / Merge, Quick, Heap sort</h1>
            <br/>
            <div className='gitlink'>

            <a href="https://github.com/LeviusN/Sorting"><button><GitHubIcon /></button></a>
            </div>
            <br/>
            <p>This project is ment to be ntroduction to algorithms </p>
            <p>It is written in Python </p>
            <li>Merge</li>
            <li>Quick</li>
            <li>Heap</li>
                <br/>
            <li>Breadth-First Search</li>
            <li>Depth-First Search</li>

            <img  style={{width: "800px", height: "500px"}} src={Img1} alt="" />
            <img  style={{width: "800px", height: "500px"}} src={Img2} alt="" />
         
        </div>
    )
}

export default SearchPage
