import React from 'react'
import "./projekt.css"
import GitHubIcon from '@mui/icons-material/GitHub';
import Video from "./MyVideo";

function SearchPage() {
    return (
        <div className="searchPage">
            <h1>Usart control</h1>
            <br/>
            <div className='gitlink'>

            <a href="https://github.com/LeviusN/USART_riadenie"><button><GitHubIcon /></button></a>
            </div>
            <br/>
            <p>This project is used to control brightness of LED using PWM and data from PC.
                User can controll behavior of LED trough Putty using UART communication between PC and STM </p>
            <p>Manual </p>
            <li>Options: $auto$ $manual$ $PWMxx$</li>
            <li>In the case when user wants to set static brightness of LED has to type $manual$ and after that $PWMxx$ where xx represents brightness of LED</li>
            <li>Range is between 00 and 99</li>
            <li>GPIOA-5</li>
            <br/>
            <Video />
        </div>
    )
}

export default SearchPage

