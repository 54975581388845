import myVideo from '../images/video1.mp4'
import React, { Component } from 'react'
import ReactPlayer from 'react-player'

class Video extends Component {
  render () {
    return (
      <div className='player-wrapper'>
        <ReactPlayer
          url={myVideo}
          controls = {true}
        />
      </div>
    )
  }
}
export default Video;