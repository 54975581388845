import React from 'react'
import "./projekt.css"
import GitHubIcon from '@mui/icons-material/GitHub';
import Img1 from '../images/refil.png'


function SearchPage() {
    return (
        <div className="searchPage">
            <h1>REFIL</h1>
            <br/>
            <div className='gitlink'>

            <a href="https://github.com/LeviusN/Sorting"><button><GitHubIcon /></button></a>
            </div>
            <br/>
            <p>This project is using recursive least square method to solve systems </p>
            <p>It is written in Matlab </p>

            <img  className='foto' src={Img1} alt="" />
            
        </div>
    )
}

export default SearchPage