import React from 'react'
import GitHubIcon from '@mui/icons-material/GitHub';
import "../SearchPage.css"
import Img from '../images/guiarm.png'

function SearchPage() {
    return (
        <div className="searchPage">
            <hr />
            <h1>Robotic manipulator in 3D space</h1>
            <br/>
            <div className='gitlink'>

            <a href="https://github.com/LeviusN/Robotic_manipulator_in_3D_space"><button><GitHubIcon /></button></a>
            </div>
            <br/>
            <p>This projet is focused on controling virtual robotic manipulator using Matlab. </p>
            <p>Project is containing following topics </p>
            <li>Forward kinematics</li>
            <li>Inverse kinematics</li>
            <li>Avoiding obstacles</li>
            <li>GUI application</li>
            <br/>
            <h3>Forward kinematics</h3>
            <p>Forward kinematics refers to process of obtaining position and velocity of end effector, given the known joint angles and angular velocities</p>
            <br/>
            <h3>Inverse kinematics</h3>
            <p>Inverse kinematics is the use of kinematic equations to determine the motion of a robot to reach a desired position.</p>
            <br/>
            <h3>Avoiding obstacles</h3>
            <p>Forward kinematics refers to process of obtaining position and velocity of end effector, given the known joint angles and angular velocities</p>
            <br/>
            <h3>GUI application</h3>
            <p>Main program to run is RRRR_program.m</p>
            <img className='foto' src={Img} alt="" />

        </div>
    )
}

export default SearchPage
