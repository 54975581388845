import React from 'react'
import "./projekt.css"
import GitHubIcon from '@mui/icons-material/GitHub';
import i2c from '../images/i2c.png'
import mcuboard from '../images/mcuboard.png'

function SearchPage() {
    return (
        <div className="searchPage">
            <h1>I2C sensor</h1>
            <br/>
            <div className='gitlink'>

            <a href="https://github.com/LeviusN/I2C_senzor"><button><GitHubIcon /></button></a>
            </div>
            <br/>
            <p>Using IMU board for sensor measurements and I2C as communication between STM and IMU</p>
            <li>Create library for sensors</li>
            <li>Calculations of those sensory data which can not be directly measured</li>
            <li>Show data on display</li>
            <li>Use button as interupt to switch between displayed sensory data</li>
            <br/>
            <img className="foto" src={i2c} alt="" />
            <img className="foto" src={mcuboard} alt="" />

        </div>
    )
}

export default SearchPage
