import React from "react";
import "./RecommendedVideos.css";
import VideoCard from "./VideoCard";
import gitlogo from './images/gitlogo.png'
import matlablogo from './images/matlablogo.png'
import pythonlogo from './images/pythonlogo.png'
import clogo from './images/clogo.png'
import javascriptlogo from './images/javascriptlogo.png'



function RecommendedVideos() {

    return (
        <div className="recommendedVideos">
            <h2>Recommended</h2>
            <div className="recommendedVideos__videos">
                <VideoCard 
                 title="Robotic manipulator in 3D space"
                 views="2.3M Views"
                 timestamp="3 days ago"
                 channelImage={gitlogo}
                 channel="Niki Popara"
                 image={matlablogo}
                 />

                <VideoCard 
                 title="Genetic Algorithm"
                 views="2.3M Views"
                 timestamp="3 days ago"
                 channelImage={gitlogo}
                 channel="Niki Popara"
                 image={pythonlogo}
                 />

                <VideoCard 
                 title="I2C comunication with STM"
                 views="2.3M Views"
                 timestamp="3 days ago"
                 channelImage={gitlogo}
                 channel="Niki Popara"
                 image={clogo}
                 />

                <VideoCard 
                 title="Usart control"
                 views="2.3M Views"
                 timestamp="3 days ago"
                 channelImage={gitlogo}
                 channel="Niki Popara"
                 image={clogo}
                 />

                <VideoCard 
                 title="File Download Upload"
                 views="2.3M Views"
                 timestamp="3 days ago"
                 channelImage={gitlogo}
                 channel="Niki Popara"
                 image={javascriptlogo}
                 />

                <VideoCard 
                 title="CLI game"
                 views="2.3M Views"
                 timestamp="3 days ago"
                 channelImage={gitlogo}
                 channel="Niki Popara"
                 image={javascriptlogo}
                 />

                <VideoCard 
                 title="Algorithms"
                 views="2.3M Views"
                 timestamp="3 days ago"
                 channelImage={gitlogo}
                 channel="Niki Popara"
                 image={pythonlogo}
                 />

                <VideoCard 
                 title="REFIL"
                 views="2.3M Views"
                 timestamp="3 days ago"
                 channelImage={gitlogo}
                 channel="Niki Popara"
                 image={matlablogo}
                 />
            </div>
        </div>
    )}
export default RecommendedVideos;
