import React from 'react'
import "./projekt.css"
import GitHubIcon from '@mui/icons-material/GitHub';
import Img from '../images/filedownload.png'

function SearchPage() {
    return (
        <div className="searchPage">
            <h1>File sharing in the local network</h1>
            <br/>
            <div className='gitlink'>

            <a href="https://github.com/LeviusN/File_Download_Upload"><button><GitHubIcon /></button></a>
            </div>
            <br/>
            <p>In this project is created local file sharing software. It is written in Javascript (React, MySql, Node Js)  </p>
            <p>What it can do </p>
            <li>Upload file</li>
            <li>Download file</li>
            <li>Delete file</li>
            <br/>
            <img  style={{width: "800px", height: "500px"}} src={Img} alt="" />
        </div>
    )
}

export default SearchPage
