import React from 'react';
import "./Sidebar.css";
import {Link} from "react-router-dom";
import SidebarRow from "./SidebarRow"
import HomeIcon from "@mui/icons-material/Home";
//import WhatshotIcon from "@mui/icons-material/Whatshot";
//import SubscriptionIcon from "@mui/icons-material/Subscriptions";
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import HistoryIcon from "@mui/icons-material/History";
//import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import ExplandMoreOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";

import InfoIcon from '@mui/icons-material/Info';
import GitHubIcon from '@mui/icons-material/GitHub';

function Sidebar() {
    return (
        <div className="sidebar">
            <Link to="/">
            <SidebarRow selected Icon={HomeIcon} title="Home"/>
            </Link>
            <Link to="/about">
            <SidebarRow Icon={InfoIcon} title="About me"/>
            </Link>
            
            <a href="https://github.com/LeviusN">
                <SidebarRow Icon={GitHubIcon} title="GitHub"/>
            </a>
            <hr />
            <Link to="/allprojects">
            <SidebarRow Icon={VideoLibraryIcon} title="Projects" />
            </Link>
            <SidebarRow Icon={HistoryIcon} title="History" />
            <Link to="/game">
            <SidebarRow Icon={SportsEsportsIcon} title="Game" />
            </Link>
            <SidebarRow Icon={WatchLaterIcon} title="Watch Later" />  
            <Link to="/like">
            <SidebarRow Icon={ThumbUpAltOutlinedIcon} title="Liked" />
            </Link>
            <SidebarRow Icon={ExplandMoreOutlinedIcon} title="Show more" />
            <hr />
        </div>
    )
}

export default Sidebar
